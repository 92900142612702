body {
  background-color: #ebedf4;
}
.DraftEditor-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: rgba(0, 0, 0, 0.87);
  line-height: 20px;
}
.terms-conditions p {
  margin-bottom: 16px;
  line-height: 20px;
}

.terms-conditions p strong {
  font-weight: 700;
}
